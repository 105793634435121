@import "~antd/dist/antd.less";

.ant-typography + h1.ant-typography {
  margin-top: 0;
}
.error {
  color: #ee2729;
}
.mark-required {
  color: #ee2729;
  font-style: italic;
}
.w-full {
  width: 100%;
}
.text-center {
  text-align: center;
}
.text-capitalize {
  text-transform: capitalize;
}
.scrollable {
  overflow-y: auto;
  position: relative;
  width: 100%;
  max-height: 384px;
  text-align: left;
}

.site-layout-header-background {
  width: 100%;
  background: #fff;
}
.site-layout-header {
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}
.site-layout-sub-header {
  max-width: 720px;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wrapper {
  position: relative;

  .slides {
    display: flex;
    padding-bottom: 1.5rem;

    .slide {
      display: none;
      width: 100%;
    }
    .active {
      display: block;
    }
  }
}
.wonderpass-logo {
  width: 100%;
  margin: auto;
  max-width: 384px;
}

.site-layout-content {
  margin: 24px 16px 0;
}

@media screen and (min-width: 640px) {
  .site-layout-sub-header-background {
    background: #fff;
  }
  .site-layout-content {
    width: 100%;
    max-width: 720px;
    margin: 24px auto 0;
  }
  .scrollable {
    text-align: justify;
  }
}

@primary-color: #EE2729;